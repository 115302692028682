@import '../../styles/variables';

.hamburger {
    z-index: 400;
    padding: 8px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    @media (min-width: $breakpoint-sm) {
        display: none;
    }

    &:after,
    &:before,
    & div {
        border-radius: 2px;
        content: '';
        display: block;
        height: 4px;
        margin: 4px 0;
        transition: all 0.2s ease-in-out;
        background-color: $color-text;
    }

    &__open:after {
        background-color: $color-primary;
        transform: translateY(-8px) rotate(-135deg);
    }
    &__open:before {
        background-color: $color-primary;
        transform: translateY(8px) rotate(135deg);
    }
    &__open div {
        transform: scale(0);
    }
}
