$color-primary: #ee6f67;
$color-primary-light: #f0817a;
$color-primary-dark: #eb564c;
$color-primary-inverse: #94160e;
$color-secondary: #edbc3c;
$color-secondary-light: #f2cd6e;
$color-secondary-dark: #ebb11f;
$color-secondary-inverse: #a6592e;
$color-tertiary: #f4f4f6;
$color-tertiary-light: #f0f2f6;
$color-default: #373b44;
$color-default-light: #525866;
$color-default-dark: #22252a;
$color-default-inverse: #67779c;
$color-text: $color-default;
$color-text-inverted: #ffffff;
$color-shadow: rgba(0, 0, 0, 0.1);
$color-text-footer: $color-default-inverse;
$color-text-footer-light: #8799b7;
$color-text-footer-dark: #67758c;
$color-contact-form-field: #ddb562;
$color-disabled: #8799b7;
$color-success: #5cbb11;
$color-fail: $color-primary-dark;

$background-primary-gradient: linear-gradient(135deg, $color-primary-light 0%, $color-primary 100%);
$background-secondary-gradient: linear-gradient(135deg, $color-secondary-light 0%, $color-secondary 100%);
$background-default-gradient: linear-gradient(135deg, $color-default-light 0%, $color-default 100%);
$background-light-gradient: linear-gradient(135deg, $color-tertiary-light 0%, $color-tertiary 100%);

$breakpoint-sm: 768px;
$breakpoint-md: 920px;
$breakpoint-lg: 1200px;
$max-width: 1240px;

$spacing-tiny: 12px;
$spacing-small: 16px;
$spacing-normal: 24px;
$spacing-medium: 32px;
$spacing-large: 64px;

$font-size-small: 16px;
$font-size-text: 18px;
$font-size-subheading: 24px;
$font-size-heading: 36px;

$section-border: 5px solid rgba(0, 0, 0, 0.1);

$component-border-radius: 4px;
