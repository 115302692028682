@import './variables.scss';

body {
    font-family: 'Palanquin', sans-serif;
    color: $color-text;
    margin: 0;
    font-size: $font-size-text;
}

h1,
h2 {
    font-size: $font-size-heading;
    font-weight: bold;
    margin: 0;
    padding-bottom: $spacing-normal;
    line-height: $font-size-heading * 1.2;
}

h3 {
    font-size: $font-size-subheading;
    font-weight: bold;
    margin: 0;
    padding-bottom: $spacing-normal;
    line-height: $font-size-subheading * 1.2;
}

h4 {
    margin: 0;
}

p {
    margin: 0;
    line-height: 24px;
}

%container {
    max-width: $max-width;
    padding: 0 $spacing-normal;
    margin: 0 auto;
}

%row {
    display: flex;
    flex-wrap: wrap;
}

%col {
    width: 100%;
    padding: $spacing-normal;
    box-sizing: border-box;
    @media (min-width: $breakpoint-sm) {
        max-width: 33%;
    }
}

.row {
    @extend %row;
}

.col {
    @extend %col;
}

%btn {
    font-size: $font-size-text;
    font-family: 'Palanquin', sans-serif;
    font-weight: bold;
    border-radius: $component-border-radius;
    color: $color-text-inverted;
    text-decoration: none;
    display: inline-block;
    padding: 0 $spacing-medium;
    height: 55px;
    line-height: 55px;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
    border: none;
    letter-spacing: 1px;
    transition: background-color 0.1s ease-in;
}

.btn-small {
    height: 42px;
    line-height: 42px;
    padding: 0 $spacing-small;
}

.btn-primary {
    @extend %btn;
    background-color: $color-primary;
    &:hover,
    &:focus {
        background: $color-primary-light;
    }
    &:hover:active {
        background: $color-primary-dark;
    }
}
.btn-secondary {
    @extend %btn;
    background-color: $color-secondary;
    &:hover,
    &:focus {
        background: $color-secondary-light;
    }
    &:hover:active {
        background: $color-secondary-dark;
    }
}
.btn-default {
    @extend %btn;
    background-color: $color-default;
    &:hover,
    &:focus {
        background: $color-default-light;
    }
    &:hover:active {
        background: $color-default-dark;
    }
    &:disabled {
        cursor: default;
        background: $color-default-light;
        opacity: 0.8;
    }
}

%reset-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.page-header {
    @extend %container;
    padding-top: $spacing-normal;
    padding-bottom: $spacing-normal;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .logo__menu-open {
        z-index: 400;
    }
    &__nav {
        display: none;
        @media (min-width: $breakpoint-sm) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: bold;
        }
        .nav-list {
            @extend %reset-ul;
            &__item {
                display: inline-block;
                padding-left: $spacing-normal;
                font-size: $font-size-small;
            }
            &__link {
                text-decoration: none;
                color: $color-default-light; // TODO ez jó szín?
                &:hover {
                    color: $color-default; // TODO ez jó szín?
                }
            }
        }
    }
    &__nav-open {
        @media (max-width: $breakpoint-sm) {
            display: block;
            background-color: white;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 200;
            padding-top: 80px;
            background-image: url('/images/services-background-right.svg');
            background-position: center right;
            background-repeat: no-repeat;

            .nav-list__item {
                display: block;
                font-size: 24px;
                font-weight: bold;
                padding: 24px 0;
                margin: 0 24px;
                border-bottom: 1px solid lightgrey;
            }
        }
    }
}

.page-header-wrapper {
    max-width: $breakpoint-lg + 2 * $spacing-normal;
    margin: 0 auto;
    background-size: 700px 604px;
    background-repeat: no-repeat;
    background-position: center top;
    @media (min-width: $breakpoint-sm) {
        background-position: right top;
    }
}

.page-hero {
    @extend %container;
    height: 505px;
    align-items: center;
    display: flex;
    justify-content: flex-start;

    &__container {
        & > h1,
        & > p {
            width: 100%;
            @media (min-width: $breakpoint-sm) {
                width: 50%;
            }
        }
        & > h1 {
            font-size: 64px;
            line-height: 64px;
        }
        &-narrow {
            max-width: 730px;
            margin: 0 auto;
        }
    }
    &__subtitle {
        padding-bottom: $spacing-normal;
        font-size: 20px;
    }
    &__description {
        padding-bottom: $spacing-normal;
    }
    &__meta {
        font-size: 14px;
        padding-bottom: $spacing-normal;
    }
}

.page-main {
    .page-blog__additional-articles {
        padding-bottom: 0;
        background: none;
    }
    .page-blog__contact {
        background-image: url('/images/services-background-left.svg'), url('/images/services-background-right.svg');
        background-position: left center, right top;
        background-repeat: no-repeat, no-repeat;
    }
    &__rolunk-container {
        @extend %container;
    }
    &__rolunk {
        background: white;

        padding: $spacing-large 0;

        background-image: url('/images/services-background-left.svg'), url('/images/services-background-right.svg');
        background-position: left center, right top;
        background-repeat: no-repeat, no-repeat;

        .product-list {
            @extend %reset-ul;

            @media (min-width: $breakpoint-sm) {
                display: flex;
                flex-flow: row nowrap;
            }
            &__item {
                border-radius: $component-border-radius;
                padding: $spacing-small * 1.2;
                padding-top: 115px;
                background-position: 0 10px;
                background-repeat: no-repeat;
                margin-bottom: $spacing-medium;
                box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
                flex-basis: 0;
                flex-grow: 1;
                &:last-child {
                    margin-bottom: 0;
                }
                @media (min-width: $breakpoint-sm) {
                    margin-right: $spacing-normal;
                    margin-bottom: 0;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &--web {
                    background-color: $color-default;
                    color: $color-default-inverse;
                    background-image: url('/images/icon-website.svg');
                }
                &--mobile {
                    background-color: $color-secondary;
                    color: $color-secondary-inverse;
                    background-image: url('/images/icon-smartphone-tablet.svg');
                }
                &--consultancy {
                    background-color: $color-primary;
                    color: $color-primary-inverse;
                    background-image: url('/images/icon-consultancy.svg');
                }
            }
        }
        .rolunk__title {
            max-width: 520px;
            margin: 0 auto;
            text-align: center;
        }
        .rolunk__description {
            max-width: 520px;
            margin: 0 auto;
            padding-bottom: $spacing-medium;
            text-align: center;
        }
        .rolunk__mvp {
            margin: 0 auto;
            font-size: $font-size-small;
            max-width: 700px;
            padding-top: $spacing-medium;
            text-align: center;
            line-height: $font-size-small * 1.1;
        }
    }

    &__references {
        //background: $color-tertiary;
        background: rgb(252, 252, 252);
        padding: $spacing-large 0;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03) inset;

        .references-container {
            @extend %container;
        }

        .references-list {
            display: flex;
            flex-wrap: wrap;
        }

        .references-item {
            @media (min-width: $breakpoint-md) {
                //display: flex;
                //flex-direction: row-reverse;
                //justify-content: space-between;
                width: calc(50% - 15px);
                margin-right: 15px;
                &:first-child {
                    width: 100%;
                    margin-right: 0;
                }
            }

            @media (min-width: $breakpoint-lg) {
                //display: flex;
                //flex-direction: row-reverse;
                //justify-content: space-between;
                width: calc(25% - 15px);
                margin-right: 15px;
                &:first-child {
                    width: 100%;
                    margin-right: 0;
                }
            }

            margin-bottom: $spacing-medium;

            .references-image {
                flex-grow: 0;
                flex-shrink: 1;

                img {
                    border-radius: $component-border-radius;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    width: 100%;
                }
            }

            .references__tags {
                color: $color-text-footer-light;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                padding-bottom: $spacing-small;
                @media (min-width: $breakpoint-md) {
                    padding-bottom: 0;
                }
            }

            .references-description {
                margin-bottom: 8px;
            }

            .references__link {
                color: $color-primary;
                font-weight: bold;
                &--disabled {
                    color: $color-disabled;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .how-we-work {
        &__container {
            @extend %container;
            padding: $spacing-large $spacing-normal;

            background-image: url('/images/bottom-background.svg');
            background-position: bottom left;
            background-repeat: no-repeat;
        }
        &__title {
            text-align: center;
        }
        &__list {
            @extend %reset-ul;
            @media (min-width: $breakpoint-sm) {
                width: 50%;
                padding-left: 50%;
            }
        }
        &__item {
            background-image: url('/images/icon-check.svg');
            background-size: 32px 32px;
            background-position: left top;
            background-repeat: no-repeat;
            min-height: 32px;
            padding: 0 45px 0;
            margin: 0 0 10px 0;
        }
    }

    .partners-container {
        @extend %container;
        padding: $spacing-large 0;
        text-align: center;
    }

    &__more-articles {
        width: 100%;
        text-align: center;
    }

    .partners-list {
        @extend %reset-ul;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &__item {
            margin: 25px 15px;
        }

        @media (min-width: $breakpoint-md) {
            flex-direction: row;

            &__item {
                margin: 0 30px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .maltai,
        .maltai > img,
        .incorpora,
        .incorpora > img {
            height: 50px;
            margin-top: 5px;
        }

        .paralimpiai,
        .paralimpiai > img {
            height: 90px;
        }

        .atmedia,
        .atmedia > img {
            height: 50px;
        }
    }

    &__journey-container {
        @extend %container;
    }
    &__journey {
        background: $color-tertiary;
        border-top: $section-border;
        text-align: center;
        padding: 64px 0;
        .journey-description {
            max-width: 480px;
            margin: 0 auto;
            padding-bottom: $spacing-normal;
        }
    }

    &__contact-container {
        @extend %container;
    }
    &__contact {
        background-color: $color-secondary;
        background-image: url('/images/contact-background-left.svg'), url('/images/contact-background-right.svg');
        background-position: left top, right bottom;
        background-repeat: no-repeat;
        background-size: contain;
        color: $color-secondary-inverse;
        text-align: center;
        padding: $spacing-large 0;
        .contact__description {
            max-width: 480px;
            margin: 0 auto;
            padding-bottom: $spacing-normal;
        }
    }
}
.page-footer {
    background: $color-default;
    color: $color-text-footer-light;
    padding: $spacing-large 0;
    p {
        font-size: $font-size-small;
    }
    &__container {
        @extend %container;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: $breakpoint-sm) {
            flex-direction: row;
        }
    }
    &__col {
        @media (min-width: $breakpoint-sm) {
            padding: 0 15px;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &--half {
                width: 40%;
            }
            &--quarter {
                width: 25%;
            }
        }
    }
    &__heading {
        margin-top: $spacing-medium;
        padding-bottom: $spacing-tiny;
        @media (min-width: $breakpoint-sm) {
            padding-bottom: $spacing-small;
            margin-top: 0;
        }
    }
    &__nav {
        .nav-list {
            @extend %reset-ul;
            &__link {
                color: $color-text-footer-light;
                font-size: $font-size-small;
                &:hover {
                    color: $color-text-footer-dark;
                }
            }
        }
    }
    &__link {
        color: $color-text-footer-light;
        &:hover {
            color: $color-text-footer-dark;
        }

        &__tel {
            text-decoration: none;
            color: $color-text-footer-light;
            &:hover {
                color: $color-text-footer-light;
            }
        }

        &__social {
            display: inline-block;
            padding: 1em 0;
            img {
                width: 2em;
                height: 2em;
            }
        }
    }
}

.page-hero__container {
    .page-blog__title {
        padding-bottom: $spacing-tiny;
        @media (min-width: $breakpoint-sm) {
            width: 100%;
        }
    }
}
.page-blog {
    &__text-container {
        @extend %container;
        font-size: 18px;
    }
    &__link {
        color: $color-primary-dark;
    }
    &__em {
        color: $color-primary-dark;
        font-style: normal;
        font-weight: bold;
    }
    &__text {
        padding: $spacing-large 0;
    }
    &__paragraph {
        padding-bottom: $spacing-normal;
        line-height: 26px;
    }
    &__intro {
        font-weight: bold;
    }
    &__text-container--columns {
        @media (min-width: $breakpoint-sm) {
            max-width: 730px;
        }
    }
    &__centered-container {
        @extend %container;
        text-align: center;
    }
    &__centered {
        max-width: 730px;
        margin: 0 auto;
    }
    &__additional-articles {
        padding: $spacing-large 0;
    }
    &__contact {
        text-align: center;
        padding: $spacing-large 0;
        .contact-form__field {
            border: 1px solid $color-text-footer-light;
        }
        .btn-default[type='submit'] {
            background-color: $color-primary;
        }
        .contact__description {
            max-width: 480px;
            margin: 0 auto;
            padding-bottom: $spacing-normal;
        }
    }
    &__contact-container {
        @extend %container;
    }
    .why-contact {
        &__list {
            @extend %reset-ul;
        }
        &__item {
        }
    }
    .separator-img-wrapper {
        padding: $spacing-medium 0;
        text-align: center;
    }
    .separator-img {
        width: 64px;
    }
    &__published {
        &-item {
            display: inline-block;
            background: #ebeced;
            vertical-align: center;
            color: $color-text-footer-dark;
            line-height: 24px;
            font-size: 14px;
            height: 24px;
            padding: 0 10px;
            border-radius: 12px;
        }
    }
    &__article-image {
        border-radius: $component-border-radius;
        width: 100%;
        margin: $spacing-normal 0;
    }
    &__additional-articles {
        background-image: url(/images/services-background-left.svg), url(/images/services-background-right.svg);
        background-position: left top, right top;
        background-repeat: no-repeat, no-repeat;
        padding-bottom: $spacing-large;
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
        padding-bottom: $spacing-normal;
        list-style-position: inside;

        li {
            margin: 0;
            padding: 0 0 0 1em;
        }
    }

    ul {
        list-style-type: none;
    }

    ul li::before {
        content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $color-primary; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
}

.article-thumbnail {
    text-align: left;
    &__date-time {
        color: #8799b7;
        font-size: $font-size-small;
    }
    &__image {
        width: 100%;
        border-radius: $component-border-radius;
    }
}

.page-kviz {
    &__leadform {
        text-align: center;
    }
    &__leadform-description {
        padding-bottom: $spacing-small;
        padding-top: $spacing-small;
        font-weight: bold;
    }
    &__leadform-button {
        margin-top: $spacing-medium;
    }
    &__leadform-success {
        padding-top: $spacing-medium;
        text-align: center;
    }
    .contact-form__field {
        border: 1px solid $color-text-footer-light;
    }
    &__additional-articles {
        background-image: url(/images/services-background-left.svg), url(/images/services-background-right.svg);
        background-position: left top, right top;
        background-repeat: no-repeat, no-repeat;
        padding: $spacing-large 0;
    }
    &__additional-quizes {
        padding: $spacing-large 0;
    }
    &__centered {
        padding-top: $spacing-large;
    }
    &__hero {
        text-align: center;
    }
    &__hero-container,
    &__questions-container {
        @extend %container;
        @media (min-width: $breakpoint-sm) {
            max-width: 730px;
        }
    }
    &__hero-image {
        width: 100%;
        border-radius: $component-border-radius;
        margin-bottom: $spacing-medium;
    }
    &__hero-title {
        padding-top: $spacing-small;
        padding-bottom: 0;
    }
    &__hero-description {
        padding-bottom: $spacing-medium;
        padding-top: 0;
    }
    &__questions-list {
        @extend %reset-ul;
    }
    &__link {
        color: $color-secondary;
    }
    &__share-link-container {
        margin-top: $spacing-medium;
        margin-bottom: $spacing-large;
        position: relative;
    }
    &__share-link {
        cursor: pointer;
        outline: none;
        font-size: $font-size-text;
        width: 100%;
        text-align: center;
        border: 2px solid $color-success;
        color: $color-success;
        border-radius: $component-border-radius;
        padding: $spacing-small;
        padding-right: 40px;
        box-sizing: border-box;
    }
    .question {
        padding-bottom: $spacing-normal;
        &__success-title {
            color: $color-success;
            font-size: $font-size-subheading;
        }
        &__share-title {
            color: $color-success;
            margin-top: $spacing-normal;
        }
        &__title {
            padding-top: 0;
            padding-bottom: $spacing-small;
            font-size: $font-size-subheading;
            line-height: 1.1;
        }
        &__answers-list {
            @extend %reset-ul;
        }
        &__answer-icon {
            height: 13px;
        }
        &__copy-icon {
            margin-left: $spacing-tiny;
            position: absolute;
            top: 19px;
            right: 14px;
            cursor: pointer;
        }
        &__copy-success-title {
            color: $color-success;
            margin-top: $spacing-normal;
        }
        &__answer-correct {
            color: $color-success;
        }
        &__answer-wrong {
            color: $color-fail;
        }
        &__reasoning-toggle {
            background: none;
            border: none;
            text-decoration: underline;
            outline: none;
            padding: 0;
            margin: 0;
            font-size: $font-size-text;
            cursor: pointer;
        }
        &__reasoning-text {
            margin-top: $spacing-small;
        }
    }
}
