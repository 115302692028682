@import "../../styles/variables";

.contact-form {
    &__alert {
        margin-bottom: $spacing-normal;
        &--warning {
            font-weight: bold;
            color: $color-secondary-inverse;
        }
        &--info {
        }
        &--error {
        }
        &--success {
        }
    }
    &__fields {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        text-align: left;
        max-width: 730px;
        margin: 0 auto;
    }
    &__field-container {
        width: 100%;
        margin-bottom: $spacing-normal;
        @media (min-width: $breakpoint-sm) {
            width: 49%;
            &--message {
                width: 100%;
            }
        }
    }
    &__field {
        display: block;
        width: 100%;
        border: none;
        background: white;
        font-weight: bold;
        font-family: "Palanquin", sans-serif;
        padding: $spacing-tiny;
        border-radius: $component-border-radius;
        box-sizing: border-box;
        font-size: $font-size-text;
        transition: all 0.1s ease-in;
        &::placeholder {
            color: rgba(0, 0, 0, 0.3);
        }
        &:focus {
            outline: none;
            background: rgba(255, 255, 255, 0.7);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        }
    }
    &__error-message {
        display: block;
        width: 100%;
        color: $color-secondary-inverse;
    }
}
.contact-form.invalid {
    .contact-form__field--invalid {
        border: 1px solid $color-secondary-inverse;
    }
    .contact-form__error-message {
        color: $color-secondary-inverse;
        font-weight: bold;
    }
}
